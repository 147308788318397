import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Form, Row, Col, Alert } from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import music from '../assets/music/anggi.mp3'
import logoig from '../assets/img/nasta/logoig.svg'
import burung from '../assets/img/nasta/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar } from '../params'
import post from '../params/post'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import covid from '../assets/img/nasta/covid.svg'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';

let cmain = '#C0573B'
let black = 'rgb(38,38,38)'

let id = 'anggi-firman'
let inisial_co = 'Firman'
let inisial_ce = 'Anggi'

let lengkap_co = (<>Firman Andrea Sebayang <br/><br/></>)
let lengkap_ce = (<>Anggi Sukma Pratiwi br. Sembiring Pandia</>)

let bapak_co = 'Kompol (Purn) Usdek Sebayang'
let ibu_co = 'Ibu Rina Hutabarat'
let bapak_ce = "Bpk. Drs. Rehmuli Sembiring Pandia"
let ibu_ce = "Ibu Dra. Sondang Bagariang"

let ig_co = "firman_asby"
let ig_ce = "anggisembiringp"

let foto_ce = "https://iili.io/F4vc41.jpg"
let foto_co = "https://iili.io/F4vaEP.jpg"
let waktunikah = "01/16/2021"

let modal = gambar("https://iili.io/F670fS.jpg",95,'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1800&h=900')
let openlogo = "https://iili.io/F67cs2.png"

let gmaps = "https://www.google.com/maps/dir//Deli+Room+-+Hotel+Danau+Toba+Medan/@-2.0082663,105.6967582,6z/data=!4m7!4m6!1m1!4e2!1m2!1m1!1s0x303131d39d39aadb:0xb9a32cc5f91e6226!3e0"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=MTlhdWhsYmRzZDFyMGR0ZXVxZHU1dmF0ZTUgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'

let slide = ["https://iili.io/FP9fK7.jpg",
    "	https://iili.io/F4VcRS.jpg	",
    "	https://iili.io/FP9ql9.jpg",
    "	https://iili.io/F4VnRI.jpg	",
    "	https://iili.io/F4VWfj.jpg	",
    "	https://iili.io/F4VwJV.jpg	",
    "	https://iili.io/F4VN5B.jpg	",
    "	https://iili.io/F4VOOP.jpg	",
    "	https://iili.io/F4VvzF.jpg	",
    "https://iili.io/FP9FPS.jpg",
]

export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 2
        }
    }

    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 2000
        });
        var countDownDate = new Date(waktunikah).getTime();

        // Update the count down every 1 second
        var x = setInterval(() => {

            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            this.setState({
                days: days, hours: hours, minutes: minutes, seconds: seconds
            })


        }, 1000);

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(music);
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${this.nama.current.value}", hadir: "${this.state.hadir}", jumlahhadir: "", kepada: "${id}", pesan:"${this.pesan.current.value}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            }else{
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }


    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, sesi } = this.state
        let slider = []
        slide.map(v => {
            slider.push(gambar(v, 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=800&h=520'))
        })
        let query = this.useQuery().get('name');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title='Undanganku - Anggi & Firman'
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slide[0]}
                    url='https://undanganku.me/anggi-firman'
                />

                <div id='gold5' style={{
                    backgroundImage: `url(${'https://i.ibb.co/gmz96rb/Background-Putih.jpg'})`,
                    backgroundSize: 'cover', backgroundPosition: 'center'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-2'
                                        src={gambar(openlogo)} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                        <div style={{fontStyle:'italic'}}>
                                            Kepada Yth :</div><div style={{fontStyle:'bold'}}>{query ? query : ''} </div>   </h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Open Invitation
                            </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                <Container className="dinny px-3 pt-5 ">
                                    {/* <Item>

                                        <p className="fs16 text-center cblack px-3">
                                            And one of His signs is that He created for you spouses from among yourselves so that you may find comfort in them. And He has placed between you compassion and mercy. Surely in this are signs for people who reflect.<br /><br />(Ar- Rum 21)

                    </p>
                                    </Item> */}
                                    <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>
                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: cmain }}>
                                            The Intimate Wedding of <br /> {inisial_ce} & {inisial_co}
                                        </p>
                                    </Item>
                                </Container>
                                <Container id='sectiongold55' className="py-5 dinny" >

                                    <Item>
                                        <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', color: cmain, fontFamily: "'Marck Script', cursive" }}>
                                                        {inisial_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_ce, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center"
                                                        style={{ fontSize: '32px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>Putri Kedua dari :</b><br />
                                                        {bapak_ce}  <br />
                                                        &<br />
                                                        {ibu_ce}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {inisial_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_co, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{
                                                        fontSize: '32px',
                                                        fontFamily: "'Marck Script', cursive", color: cmain
                                                    }}>
                                                        {lengkap_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>Putra Kedua dari:</b><br />
                                                        {bapak_co}
                                                        <br />
                                                        &<br />
                                                        {ibu_co}
                                                    </p>
                                                </Item>
                                                <Item>
                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                    </Item>
                                </Container>
                                <Container fluid className="text-center px-4 dinny" style={{ color: black }} >
                                    <Item>
                                        <p className="fs16">
                                            Yang akan dilaksanakan pada:
                                         </p>
                                    </Item>
                                    {/* <Item>
                                        <p className="fs20">
                                            <b>
                                                SABTU <span className="fs36">12</span> DES 2020
                                    </b>
                                        </p>
                                    </Item> */}
                                    <Item>
                                        <Col xs={12} md={4} >
                                        <p className="fs20 w-100 text-center" style={{ color: cmain }}>
                                            <b>Pemberkatan </b><br />
                                           
                                            
                                        </p>
                                        <p className="fs20 w-100 text-center" style={{ color: cmain }}>
                                           
                                            <span className="cblack fs16">
                                                <b>
                                                    15 Januari 2021
                                                </b><br />
                                            10.00 WIB - Selesai<br/><br/>
                                            <div style={{ fontStyle: 'italic' }}>
                                                    <b>Gereja Katolik Kristus Raja Perdagangan</b> <br />
                                            Jln. Jendral Sudirman No. 03 Perdagangan I.<br />
                                                Bandar, Kab. Simalungun, Sumatera Utara, 21184

                                            </div>
                                            </span>
                                      
                                        </p>
                                        </Col>
                                       
                                        <div className="col-8 d-sm-none" style={{ borderBottom: `2px solid ${cmain}` }}>
                                        </div>
                                        <Col xs={12} md={4}>
                                        <p className="fs20 pt-3 pt-sm-0 w-100 text-center" style={{color: '#C0573B'}}>
                                            <b>Resepsi</b><br />

                                


                                        </p>
                                        <p className="fs20 pt-3 pt-sm-0 w-100 text-center" style={{color: '#C0573B'}}>
                                            
                                            <span className="cblack fs16">
                                                <b>16 Januari 2021
                                                    </b><br />
                                                    18.00 WIB - Selesai<br/><br/>
                                                    <div style={{ fontStyle: 'italic' }}>
                                                    <b>Deli Room - Hotel Danau Toba Medan </b><br/>
                                                    Jl. Imam Bonjol No.17, Madras Hulu, <br/>
                                                    Kec. Medan Polonia, Kota Medan, Sumatera Utara 20112

                                                    </div>
                                            </span>


                                        </p>
                                        </Col>
                                    </Item>
                                    {/* <Item>
                                        <p className="fs16 pt-3">
                                            <b>Jln. Lintas Timur RT 15 Kel. Sengeti, Kec. Sekernan,
                                            <br />
                                            Kab. Muaro Jambi Sekernan,  36381
                                            </b>
                                        </p>
                                    </Item> */}
                                    <Item>
                                        <p style={{ fontSize: '16px', fontStyle: 'italic' }}>Undangan Hanya untuk 2 orang</p>
                                    </Item>
                                    {/* <Item>
                                        <div className="mapouter m-3"><div className="gmap_canvas text-center">
                                            <iframe width="400" height="300" id="gmap_canvas"
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.5023332658748!2d103.50863051432627!3d-1.4716306362704528!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMcKwMjgnMTcuOSJTIDEwM8KwMzAnMzkuMCJF!5e0!3m2!1sen!2sid!4v1606611948316!5m2!1sen!2sid" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://www.whatismyip-address.com/nordvpn-coupon/"></a></div>
                                        </div>
                                    </Item> */}
                                    <Item>
                                        <Col xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open(gmaps)
                                            }}
                                            className="p-2 mx-sm-2 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>

                                </Container>
                                <Container id='sectiongold57'>
                                    <div className='pt-3'>

                                        <Item>
                                            <div data-aos="fade-left" data-aos-duration="1000"
                                                className='col-10 col-lg-8 kotak' style={{ backgroundColor: cmain }}>
                                                <Item>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {days}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Days
                      </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot'>:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {hours}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Hours
                      </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot'>:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div >
                                                                {minutes}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Mins
                      </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot' >:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {seconds}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Secs
                      </span>
                                                        </Item>
                                                    </div>
                                                </Item>

                                            </div>
                                        </Item>
                                    </div>
                                </Container>
                                {/* <Container className="text-center py-5 dinny" id="dinny">
                                    <>
                                        <Item>
                                            <h1 style={{
                                                fontSize: '72px',
                                                fontFamily: "'Marck Script', cursive",
                                                color: cmain
                                            }}>
                                                Rundown
                          </h1>
                                        </Item>

                                        <Item>
                                            <Col sm={2} xs={4}>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/2905/2905065.svg" className="img-fluid w-100 p-2" />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <p className="cblack fs16">
                                                <b>
                                                    08.00 - 10.00
                        </b><br />
                        Akad Nikah
                      </p>
                                        </Item>
                                        <Item>
                                            <Col sm={4} className="pt-sm-3">
                                                <Item>
                                                    <Col xs={8} className="order-2 order-sm-1">
                                                        <p className="cblack text-left text-sm-right fs16">
                                                            <b>
                                                                11.00 - 11.15
                              </b><br />
                              Wedding Entrance

                            </p>
                                                    </Col>
                                                    <Col sm={4} xs={3} className="order-1 order-sm-2">
                                                        <img src="https://www.flaticon.com/svg/static/icons/svg/1110/1110062.svg" className="img-fluid w-100" />
                                                    </Col>
                                                </Item>
                                                <div className="d-block opa">
                                                    <Item>
                                                        <Col xs={3} sm={4} >
                                                            <img src="https://www.flaticon.com/svg/static/icons/svg/1473/1473885.svg" className="img-fluid w-100" />
                                                        </Col>
                                                        <Col xs={8}>
                                                            <p className="cblack text-left fs16">
                                                                <b>
                                                                    11.00 - 11.30
                              </b><br />
                              Photo Session
                            </p>
                                                        </Col>
                                                    </Item>
                                                </div>

                                                <Item>
                                                    <Col xs={8} className="order-2 order-sm-1">
                                                        <p className="cblack text-left text-sm-right fs16">
                                                            <b>
                                                                11.00 - 13.00
                              </b><br />
                              Enjoy the live music performance
                            </p>
                                                    </Col>
                                                    <Col sm={4} xs={3} className="order-1 order-sm-2">
                                                        <img src="https://www.flaticon.com/svg/static/icons/svg/926/926338.svg" className="img-fluid w-100" />
                                                    </Col>
                                                </Item>


                                            </Col>


                                            <Col sm={4} className="pt-sm-3">

                                                <div style={{ opacity: 0 }} className="d-none d-sm-block">
                                                    <Item>
                                                        <Col sm={8}>
                                                            <p className="cblack text-right fs16">
                                                                <b>
                                                                    11.00 - 11.15
                              </b><br />
                              Wedding Entrance

                            </p>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <img src="https://www.flaticon.com/svg/static/icons/svg/1110/1110062.svg" className="img-fluid w-100" />
                                                        </Col>
                                                    </Item>
                                                </div>
                                                <div>

                                                </div>
                                                <div className="d-none d-sm-block">
                                                    <Item>
                                                        <Col sm={4} xs={3}>
                                                            <img src="https://www.flaticon.com/svg/static/icons/svg/1473/1473885.svg" className="img-fluid w-100" />
                                                        </Col>
                                                        <Col xs={8} >
                                                            <p className="cblack text-left fs16">
                                                                <b>
                                                                    11.00 - 11.30
                              </b><br />
                             Family Photo Session
                            </p>
                                                        </Col>

                                                    </Item>
                                                </div>
                                                <div style={{ opacity: 0 }} className="d-none d-sm-block">
                                                    <Item>

                                                        <Col sm={8}>
                                                            <p className="cblack text-right fs16">
                                                                <b>
                                                                    11.00 - 13.00
                              </b><br />
                              Enjoy the live music performance
                            </p>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <img src="https://www.flaticon.com/svg/static/icons/svg/926/926338.svg" className="img-fluid w-100" />
                                                        </Col>
                                                    </Item>
                                                </div>





                                            </Col>
                                        </Item>
                                    </>
                                </Container> */}

                                <Container className="py-3">
                                    <Item>
                                        <Col xs={12} md={6}>
                                            <img src={covid} className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                </Container>
                                {/* <Container className="text-center dinny">
                  <Item>
                    <Col className="py-3 px-sm-5" style={{ border: `2px solid ${cmain}`, borderRadius: '10px' }}>
                      <h1 className="fs30"
                        style={{

                          fontFamily: "'Marck Script', cursive",
                          color: '#B99225'
                        }}>
                        New Normal Rules
                          </h1>
                      <Item>
                        <Col xs={12}>
                          <p className="cblack text-center w-100 fs16">
                            Demi keamanan dan kenyamanan bersama, para hadirin undangan dihimbau untuk memenuhi beberapa peraturan berikut:
                          </p>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={mask} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                              Gunakan Masker
                        </p>
                          </Row>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={distance} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                              jaga Jarak
                      </p>
                          </Row>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={salaman} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                              Cukup Bersalaman tanpa Bersentuhan
                      </p>
                          </Row>
                        </Col>
                      </Item>
                    </Col>
                  </Item>

                  <Item>
                    <Col>
                    </Col>

                  </Item>

                </Container> */}


                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3'>

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4 fs14'>
                                                        Now this is not the end. It is not even the
                                                         beginning of the end. But it is, perhaps,
                                                          the end of the beginning <br/>- Winston Churchill -
</p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>

                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                        <Item>
                                            <Col xs={4} lg={2}>
                                                <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: cmain
                                                    }}>
                                                        Send Your Wishes
                    </h1>
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                        <Item>
                                                            <div id="formradio">
                                                                <div class="custom_radio row justify-content-center">
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: true })
                                                                    }
                                                                    }>
                                                                        <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                                                        <label for="featured-1">Hadir</label>
                                                                    </div>
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: false })
                                                                    }
                                                                    } className="pl-5">
                                                                        <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                                                        <label for="featured-2"

                                                                        >Tidak Hadir</label>
                                                                    </div>
                                                                    {/* {!hadir ? false : (
                                                                        <>  <Alert variant='dark col-12 mr-4 '>
                                                                            <p className="f-14">
                                                                                Silahkan Pilih Jadwal Kedatangan
                                      </p>
                                                                            <div onClick={() => {
                                                                                this.setState({ sesi: 2 })
                                                                            }
                                                                            }
                                                                                className="pl-5">
                                                                                <input type="radio" id="featured-3" name="sesi" checked={sesi == 2 ? true : false} />
                                                                                <label for="featured-3">

                                                                                    Sesi 2 : 11.00 - 12.00</label>
                                                                            </div>
                                                                            <div onClick={() => {
                                                                                this.setState({ sesi: 3 })
                                                                            }
                                                                            } className="pl-5">
                                                                                <input type="radio" id="featured-4" name="sesi" checked={sesi == 3 ? true : false} />
                                                                                <label for="featured-4"

                                                                                >Sesi 3 : 12.00 - 13.00</label>
                                                                            </div>
                                                                            <p className="f-14">
                                                                                Tamu undangan diharapkan hadir sesuai dengan sesi yang telah ditentukan

                                      </p>
                                                                        </Alert>



                                                                        </>

                                                                    )} */}

                                                                </div>
                                                            </div>
                                                        </Item>

                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                        </Item>
                                                    </form>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>

                                <Foot ig={logoig} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

